<template>
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal width="80%">
			<template #modal-title>
				Design Data Export
				<v-spacer />
				<IconButton
					@click="$emit('close')"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<v-row>
					<v-col cols="2">
						<Dropdown
							v-model="selectedDataObject"
							:label="'Object To Register *' | useLabels"
							class="cassie-input-width-xl"
							:items="registeredDataObjects"
							rules="required"
							@change="getExportDesign(selectedDataObject)"
						/>
					</v-col>
					<v-col cols="2">
						<Dropdown
							v-model="design.fullExport"
							class="cassie-input-width-xl"
							:label="'Export Type *' | useLabels"
							:items="exportTypes"
							rules="required"
						/>
					</v-col>
					<v-col
						v-if="!design.fullExport"
						cols="2"
					>
						<Dropdown
							v-model="design.incrementalField"
							class="cassie-input-width-xl"
							:label="'Incremental Field *' | useLabels"
							:items="incrementFields"
							rules="required"
						/>
					</v-col>
				</v-row>
				<v-simple-table
					v-if="design.design.length > 1"
					fixed-header
					height="700"
				>
					<template #default>
						<thead>
							<tr>
								<th
									class="text-left"
									scope="col"
								>
									Sequence
								</th>
								<th
									class="text-left"
									scope="col"
								>
									Field
								</th>
								<th
									class="text-left"
									style="width: 20%;"
									scope="col"
								>
									Heading
								</th>
								<th
									class="text-left"
									style="width: 20%;"
									scope="col"
								>
									Filter
								</th>
								<th
									class="text-left"
									scope="col"
								>
									Data type (size)
								</th>
								<th
									class="text-left"
									scope="col"
								>
									Action
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in design.design"
								:key="item.fieldName"
							>
								<td class="text-left">
									{{ item.fieldOrder === 0 ? '-' : item.fieldOrder }}
								</td>
								<td
									class="text-left"
								>
									{{ item.fieldName }}
								</td>
								<td class="text-left">
									<TextField
										:disabled="item.fieldOrder === 0 || item.fieldOrder === null"
										:value="item.fieldAlias"
										:rules="{max:50}"
										small
										label="Heading"
										@input="updateDesignField(index, 'fieldAlias', $event)"
									/>
								</td>
								<td
									class="text-left"
									:title="item.displayFilter"
								>
									<TextField
										:disabled="item.fieldOrder === 0 || item.fieldOrder === null"
										view-only
										:value="item.displayFilter"
										small
										label="Filter"
										clearable
										@click:clear="clearDisplayFilter(index)"
										@click="handleShowQueryBuilder(index, item.dataType, item.filter)"
									/>
								</td>
								<td
									class="text-left"
									style="width: 15%;"
								>
									{{ item.dataType }} ({{ item.dataSize }})
								</td>
								<td class="text-left">
									<IconButton
										v-if="item.fieldOrder > 0 && index !== 0"
										class="mr-2"
										small
										@click="moveFieldUp(index)"
									>
										mdi-arrow-up
									</IconButton>
									<IconButton
										v-if="item.fieldOrder > 0 && !isLastField(index)"
										class="mr-2"
										small
										@click="moveFieldDown(index)"
									>
										mdi-arrow-down
									</IconButton>
									<IconButton
										v-if="item.fieldOrder === null || item.fieldOrder === 0"
										small
										class="mr-2"
										tooltip-text="Add Field"
										@click="includeField(index)"
									>
										mdi-check
									</IconButton>
									<IconButton
										v-if="item.fieldOrder > 0"
										small
										tooltip-text="Remove Field"
										@click="excludeField(index)"
									>
										mdi-trash-can
									</IconButton>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<div
					v-if="design.design.length > 1"
					class="sortFields"
				>
					<div class="sortField">
						<Dropdown
							v-model="sortField1.fieldName"
							:label="'Sort 1' | useLabels"
							class="cassie-input-width-xl"
							:items="sortFields"
							item-disabled="disabled"
							clearable
							@change="changeSortField(1, $event)"
						/>
						<v-icon
							v-if="sortField1.sortDirection === 'asc'"
							@click="sortField1.sortDirection = 'desc'"
						>
							mdi-arrow-up
						</v-icon>
						<v-icon
							v-if="sortField1.sortDirection === 'desc'"
							@click="sortField1.sortDirection = 'asc'"
						>
							mdi-arrow-down
						</v-icon>
					</div>
					<div class="sortField">
						<Dropdown
							v-model="sortField2.fieldName"
							:disabled="!sortField1.fieldName"
							class="cassie-input-width-xl"
							:label="'Sort 2' | useLabels"
							:items="sortFields"
							item-disabled="disabled"
							clearable
							@change="changeSortField(2, $event)"
						/>
						<v-icon
							v-if="sortField2.sortDirection === 'asc'"
							@click="sortField2.sortDirection = 'desc'"
						>
							mdi-arrow-up
						</v-icon>
						<v-icon
							v-if="sortField2.sortDirection === 'desc'"
							@click="sortField2.sortDirection = 'asc'"
						>
							mdi-arrow-down
						</v-icon>
					</div>
					<div class="sortField">
						<Dropdown
							v-model="sortField3.fieldName"
							:disabled="!sortField2.fieldName"
							class="cassie-input-width-xl"
							:label="'Sort 3' | useLabels"
							:items="sortFields"
							item-disabled="disabled"
							clearable
							@change="changeSortField(3, $event)"
						/>
						<v-icon
							v-if="sortField3.sortDirection === 'asc'"
							@click="sortField3.sortDirection = 'desc'"
						>
							mdi-arrow-up
						</v-icon>
						<v-icon
							v-if="sortField3.sortDirection === 'desc'"
							@click="sortField3.sortDirection = 'asc'"
						>
							mdi-arrow-down
						</v-icon>
					</div>
				</div>
				<ConfirmationModal
					v-if="showPreviewConfirmationModal"
					:title-text="'Generate Data Export Preview?'"
					@cancel="showPreviewConfirmationModal = false"
					@confirm="handleSubmit(submit)"
				>
					<template #modal-content>
						This will create an export file from the current data in the database (maximum 200 rows) and download it to your browser.	<br>
						In order to preview your export design, it will be saved first. Do you wish to proceed?
					</template>
				</ConfirmationModal>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<TertiaryActionButton	@click="handleSubmit(showPreviewModal)">
					Preview
				</TertiaryActionButton>
				<PrimaryActionButton @click="handleSubmit(submit)">
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
		<QueryBuilderModal
			v-if="showQueryBuilder"
			:query-builder-item="queryBuilderItem"
			@cancel="showQueryBuilder = false"
			@confirm="handleConfirmQueryBuilder($event)"
		/>
	</Validationform>
</template>
<script>
import Modal from '../../../../../shared/components/modal.vue'
import ConfirmationModal from '../../../../../shared/components/confirmation-modal.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import { getRegisteredDataObjects } from '../../../../../shared/utils/api/data-objects.js'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import { getDataExportDesign, createDataExportDesign, updateDataExportDesign, getDataExportPreview } from '../../../../../shared/utils/api/data-exports.js'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import TertiaryActionButton from '../../../../../shared/components/tertiary-action-button.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import QueryBuilderModal from '../../../../../shared/components/query-builder.vue'

export default {
	components: {
		Modal,
		IconButton,
		Dropdown,
		TextField,
		PrimaryActionButton,
		SecondaryActionButton,
		ValidationForm,
		QueryBuilderModal,
		TertiaryActionButton,
		ConfirmationModal
	},
	props: {
		exportId: {
			type: Number,
			default: null
		}
	},
	data () {
		return {
			designState: '',
			registeredDataObjects: [],
			selectedDataObject: null,
			exportType: null,
			showQueryBuilder: false,
			queryBuilderItem: {
				index: null,
				dataType: '',
				filter: {
					operator: null,
					values: []
				}
			},
			design: {
				fullExport: true,
				incrementalField: '',
				dataObjectId: null,
				design:
				[{
					fieldName: '',
					fieldOrder: null,
					fieldAlias: '',
					dataType: '',
					dataSize: null,
					filter: '',
					sortOrder: '',
					sortDirection: '',
					legacyFilter: '',
					displayFilter: ''
				}]
			},
			exportTypes: [{ value: true, text: 'Full' }, { value: false, text: 'Incremental' }],
			incrementFields: [],
			incrementalField: '',
			sortField1: { fieldName: '', sortDirection: 'asc' },
			sortField2: { fieldName: '', sortDirection: 'asc' },
			sortField3: { fieldName: '', sortDirection: 'asc' },
			showPreviewConfirmationModal: false
		}
	},
	computed: {
		sortFields () {
			const selectedSortFields = [
				this.sortField1.fieldName,
				this.sortField2.fieldName,
				this.sortField3.fieldName
			]
			return this.design.design.filter(field => field.fieldOrder > 0)
				.map(field => { return { value: field.fieldName, text: field.fieldName, disabled: selectedSortFields.includes(field.fieldName) } })
		}
	},

	async created () {
		await getRegisteredDataObjects().then(response => {
			const responseData = JSON.parse(JSON.stringify(response.data.registeredDataObjects))
			responseData.forEach(item => {
				this.registeredDataObjects.push({ value: item.id, text: item.displayName })
			})
		})
		this.getExportDesign()
	},
	methods: {
		async getExportDesign (dataObjectId) {
			const { data: design } = await getDataExportDesign(this.exportId, dataObjectId)
			if (design.design.some(field => field.fieldOrder > 0)) { this.designState = 'PUT' } else { this.designState = 'POST' }
			design.design = design.design.map((field, index) => {
				return {
					fieldName: field.fieldName,
					fieldOrder: this.designState === 'PUT' ? field.fieldOrder : index + 1,
					fieldAlias: field.fieldAlias ? field.fieldAlias : field.fieldName,
					dataType: field.dataType,
					dataSize: field.dataSize,
					filter: field.filter,
					sortOrder: field.sortOrder,
					sortDirection: field.sortDirection,
					displayFilter: field.legacyFilter
				}
			})
			this.design = design
			this.design.design = design.design.filter(
				field => field.fieldOrder > 0).sort(
				(a, b) => a.fieldOrder - b.fieldOrder).concat(
				design.design.filter(
					field => field.fieldOrder === 0))
			this.selectedDataObject = this.design.dataObjectId !== 0 ? this.design.dataObjectId : null
			this.incrementFields = design.design.filter((field, index) => field.dataType === 'datetime' || field.dataType === 'int' && field.fieldName.includes('ID') || index === 0).map(field => { return { value: field.fieldName, text: field.fieldName } })
			this.sortField1 = {
				fieldName: this.design.design.filter(field => field.sortOrder === 1)[0]?.fieldName || '',
				sortDirection: this.design.design.filter(field => field.sortOrder === 1)[0]?.sortDirection || 'asc'
			}
			this.sortField2 = {
				fieldName: this.design.design.filter(field => field.sortOrder === 2)[0]?.fieldName || '',
				sortDirection: this.design.design.filter(field => field.sortOrder === 2)[0]?.sortDirection || 'asc'
			}
			this.sortField3 = {
				fieldName: this.design.design.filter(field => field.sortOrder === 3)[0]?.fieldName || '',
				sortDirection: this.design.design.filter(field => field.sortOrder === 3)[0]?.sortDirection || 'asc'
			}
			this.design.design.forEach((design, index) => {
				this.structureFilterOnLoad(index, design.filter)
			})
		},
		clearDisplayFilter (index) {
			this.design.design[index].displayFilter = null
			this.design.design[index].filter = null
		},
		structureFilterOnLoad (index, fieldFilter) {
			const operators = [
				{ value: 1, text: 'equal to' },
				{ value: 2, text: 'not equal to' },
				{ value: 3, text: 'less than' },
				{ value: 4, text: 'greater than' },
				{ value: 5, text: 'between' }
			]
			if (!fieldFilter) return
			if (fieldFilter.operator === 5) {
				this.design.design[index].displayFilter = `between ${fieldFilter.values[0].value} and ${fieldFilter.values[1].value}`
			} else if ((fieldFilter.operator === 1 || fieldFilter.operator === 2) && fieldFilter.values.length > 1) {
				const vals = Array.from(fieldFilter.values, el => { return el.value })
				this.design.design[index].displayFilter = `${operators.find(({ value }) => value === fieldFilter.operator).text} ${vals.slice(-vals.length, -1).join(', ')} or ${vals.at(-1)}`
			} else {
				this.design.design[index].displayFilter = `${operators.find(({ value }) => value === fieldFilter.operator).text} ${fieldFilter.values[0].value}`
			}
		},
		showPreviewModal () {
			this.showPreviewConfirmationModal = true
		},
		updateDesignField (index, property, value) {
			this.design.design[index][property] = value
		},
		includeField (index) {
			const firstItem = this.design.design.filter(item => item.fieldOrder === 0)[0]
			const firstItemIndex = this.design.design.indexOf(firstItem)
			this.design.design[index].fieldOrder = firstItemIndex + 1
			this.design.design.splice(firstItemIndex, 0, this.design.design.splice(index, 1)[0])
		},
		excludeField (index) {
			const itemsAboveZero = this.design.design.filter(item => item.fieldOrder > 0)
			this.design.design[index].fieldOrder = 0
			if (itemsAboveZero.length > 0) {
				const lastItemAboveZero = itemsAboveZero[itemsAboveZero.length - 1]
				const lastItemIndex = this.design.design.indexOf(lastItemAboveZero)
				const item = this.design.design.splice(index, 1)[0]
				this.design.design.splice(lastItemIndex, 0, item)
			}
			this.design.design.forEach((item, index) => {
				if (item.fieldOrder > 0) { item.fieldOrder = index + 1 }
			})
		},
		moveFieldUp (index) {
			const arrayCopy = [...this.design.design]
			const currentItem = arrayCopy.splice(index, 1)[0]
			const previousItem = arrayCopy[index - 1]
			currentItem.fieldOrder--
			previousItem.fieldOrder++
			arrayCopy.splice(index - 1, 0, currentItem)
			this.design.design = arrayCopy
		},
		moveFieldDown (index) {
			const arrayCopy = [...this.design.design]
			const currentItem = arrayCopy.splice(index, 1)[0]
			const nextItem = arrayCopy[index]
			currentItem.fieldOrder++
			nextItem.fieldOrder--
			arrayCopy.splice(index + 1, 0, currentItem)
			this.design.design = arrayCopy
		},
		isLastField (index) {
			return index === this.design.design.length - 1 || this.design.design[index + 1]?.fieldOrder === 0
		},
		handleShowQueryBuilder (index, dataType, filter) {
			this.queryBuilderItem = { index, dataType, filter: JSON.parse(JSON.stringify(filter)) }
			this.showQueryBuilder = true
		},
		handleConfirmQueryBuilder (query) {
			const filter = { operator: query.operator, values: query.values }
			this.design.design[this.queryBuilderItem.index].filter = filter
			if (query.operator === 5) {
				this.design.design[this.queryBuilderItem.index].displayFilter = query.operatorText + ' ' + query.values[0].value + ' and ' + query.values[1].value
			} else if ((query.operator === 1 || query.operator === 2) && query.values.length > 1) {
				const vals = Array.from(query.values, el => { return el.value })
				this.design.design[this.queryBuilderItem.index].displayFilter = query.operatorText + ` ${vals.slice(-vals.length, -1).join(', ')} or ${vals.at(-1)}`
			} else {
				this.design.design[this.queryBuilderItem.index].displayFilter = query.operatorText + ' ' + query.values[0].value
			}
			this.showQueryBuilder = false
		},
		changeSortField (sortFieldValue, value) {
			if (sortFieldValue === 1 && value === null) {
				this.sortField1 = this.sortField2
				this.sortField2 = this.sortField3
				this.sortField3 = { fieldName: '', sortDirection: 'asc' }
			}
			if (sortFieldValue === 2 && value === null) {
				this.sortField2 = this.sortField3
				this.sortField3 = { fieldName: '', sortDirection: 'asc' }
			}
		},
		async submit () {
			const isPreview = this.showPreviewConfirmationModal
			this.showPreviewConfirmationModal = false
			let errorSaving = false
			const updatedDesign = {
				exportId: this.exportId,
				fullExport: this.design.fullExport,
				dataObjectId: this.design.dataObjectId,
				incrementalField: !this.design.fullExport ? this.design.incrementalField : null,
				design: this.design.design.filter(field => field.fieldOrder > 0).map(field => {
					const newField = {
						fieldName: field.fieldName,
						fieldOrder: field.fieldOrder,
						fieldAlias: field.fieldAlias,
						dataType: field.dataType,
						dataSize: field.dataSize,
						filter: field.filter,
						sortOrder: field.sortOrder,
						sortDirection: field.sortDirection
					}
					if (field.fieldName === this.sortField1.fieldName) {
						newField.sortDirection = this.sortField1.sortDirection
						newField.sortOrder = 1
					} else if (field.fieldName === this.sortField2.fieldName) {
						newField.sortDirection = this.sortField2.sortDirection
						newField.sortOrder = 2
					} else if (field.fieldName === this.sortField3.fieldName) {
						newField.sortDirection = this.sortField3.sortDirection
						newField.sortOrder = 3
					}
					return newField
				})
			}
			if (this.designState === 'POST') {
				await createDataExportDesign(updatedDesign)
					.then(() => {
						showSnackbar('Data Export design created')
						if (!isPreview) this.$emit('close')
					})
					.catch(() => {
						showSnackbar({ text: 'Error creating Data Export design', color: 'red' })
						errorSaving = true
					})
			} else if (this.designState === 'PUT') {
				await updateDataExportDesign(updatedDesign)
					.then(() => {
						showSnackbar('Data Export design updated')
						if (!isPreview) this.$emit('close')
					})
					.catch(() => {
						showSnackbar({ text: 'Error updating Data Export design', color: 'red' })
						errorSaving = true
					})
			}
			if (isPreview && !errorSaving) {
				await getDataExportPreview(this.exportId)
					.then(response => {
						const fileURL = window.URL.createObjectURL(new Blob([response.fileContent]))
						const fileLink = document.createElement('a')
						fileLink.href = fileURL
						fileLink.setAttribute('download', response.fileName)
						document.body.appendChild(fileLink)
						fileLink.click()
						showSnackbar('Preview generated')
					})
					.catch(() => {
						showSnackbar({ text: 'Error generating Data Export preview', color: 'red' })
					})
			}
		}
	}
}
</script>
<style lang="css">
.sortFields {padding-top: 20px;display: flex;flex-direction: row;align-items: center;gap: 20px;}
.sortFields .sortField {display: flex;flex-direction: row;align-items: center;gap: 10px;},
.empty-design {display: flex;flex-direction: column;align-items: center;justify-content: center;height: 100%;}
</style>
