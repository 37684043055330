<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			:search-query-label="'Data Object Name' | useLabels"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="onCreateClick"
				>
					<v-icon
						left
						dark
					>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Data Objects' | useLabels }}
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredDataObjects"
					sort-by="id"
					sort-desc
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="item.possiblyOutOfDate && userFullPermissions"
							tooltip-text="Refresh Data Object"
							@click="onRefreshClick(item)"
						>
							mdi-refresh
						</IconButton>
						<IconButton
							v-if="userFullPermissions"
							tooltip-text="Delete Data Object"
							@click.stop.prevent="handleDeleteClick(item)"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</Sectioncard>
		<ConfirmDeleteModal
			v-if="dataObjectToRemove"
			:entity-name="dataObjectToRemove.displayName"
			entity-type="Data Object"
			@close="onCloseDeleteModal()"
			@delete="removeDataObject(dataObjectToRemove)"
		>
			<template
				v-if="exportsUsingDataObject"
				#additional-content
			>
				<br>
				<br>
				This data object is currently being used by the following export(s):
				<v-simple-table>
					<thead>
						<tr>
							<th
								class="text-left"
								scope="col"
							>
								ID
							</th>
							<th
								class="text-left"
								scope="col"
							>
								Data export name
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="item in exportsUsingDataObject"
							:key="item.id"
						>
							<td>
								{{ item.id }}
							</td>
							<td>
								{{ item.name }}
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</template>
		</ConfirmDeleteModal>
		<ConfirmationModal
			v-if="showRefreshModal"
			:title-text="`Refresh ${dataObjectToRefresh.displayName}?`"
			@cancel="showRefreshModal = false"
			@confirm="onRefreshConfirm(dataObjectToRefresh.id)"
		>
			<template #modal-content>
				This will refresh the data object with the latest data from the source.
			</template>
		</ConfirmationModal>
		<CreateDataObjectModal
			v-if="showCreateDataObjectModal"
			@close="onCloseCreateDataObjectModal"
			@register-object="onRegisterObject"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import DataTable from '../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import { getRegisteredDataObjects, refreshDataObject, deleteRegisteredDataObject, getDataObjectUse, postRegisterDataObject } from '../../../../../shared/utils/api/data-objects.js'
import ConfirmationModal from '../../../../../shared/components/confirmation-modal.vue'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import CreateDataObjectModal from './create-data-object-modal.vue'
import { CAN_SEE_EXPORTS_MODULE, EXPORTS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	name: 'data-objects-tab',
	components: { FilterBar, DataTable, SectionCard, PrimaryActionButton, IconButton, ConfirmDeleteModal, ConfirmationModal, CreateDataObjectModal },
	data () {
		return {
			tableHeaders: [
				{ text: 'ID', value: 'id', width: '10%' },
				{ text: 'Data Object Name', value: 'displayName', width: '30%' },
				{ text: 'Created Date', value: 'createdDate', width: '20%' },
				{ text: 'Action', value: 'action', width: '10%', sortable: false }
			],
			dataObjects: [],
			searchQuery: null,
			dataObjectToRemove: null,
			exportsUsingDataObject: null,
			showRefreshModal: false,
			dataObjectToRefresh: null,
			showCreateDataObjectModal: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userFullPermissions () {
			return this.productAreaPermission(EXPORTS_FULL_PERMISSIONS)
		},
		userReadOnly () {
			return this.productAreaPermission(CAN_SEE_EXPORTS_MODULE)
		},
		filteredDataObjects () {
			return this.dataObjectsDateFormatted.filter(({ displayName }) => {
				let check = true
				if (this.searchQuery) check = displayName.toLowerCase().includes(this.searchQuery.toLowerCase())
				return check
			})
		},
		dataObjectsDateFormatted () {
			return this.dataObjects.map(dataObject => {
				return {
					...dataObject,
					createdDate: format(new Date(dataObject.createdDate), DATE_FORMAT)
				}
			})
		}
	},
	created () {
		this.loadDataObjects()
	},
	methods: {
		async loadDataObjects () {
			const { data: { registeredDataObjects } } = await getRegisteredDataObjects()
			this.dataObjects = registeredDataObjects
		},
		async refreshDataObject (dataObjectId) {
			await refreshDataObject(dataObjectId)
			this.loadDataObjects()
		},
		onCreateClick () {
			this.showCreateDataObjectModal = true
		},
		async removeDataObject (dataObjectToRemove) {
			await deleteRegisteredDataObject(dataObjectToRemove.id)
			showSnackbar(useLabels(`Successfully deleted ${dataObjectToRemove.displayName}`))
			this.dataObjectToRemove = null
			this.loadDataObjects()
		},
		async getDataObjectUse (dataObjectId) {
			const { data: { exportsUsingDataObject } } = await getDataObjectUse(dataObjectId)
			if (exportsUsingDataObject.length) {
				this.exportsUsingDataObject = exportsUsingDataObject
			}
		},
		handleDeleteClick (dataObject) {
			this.dataObjectToRemove = dataObject
			this.getDataObjectUse(dataObject.id)
		},
		onRefreshConfirm (objectId) {
			this.refreshDataObject(objectId)
			this.showRefreshModal = false
		},
		onRefreshClick (dataObject) {
			this.dataObjectToRefresh = dataObject
			this.showRefreshModal = true
		},
		onCloseDeleteModal () {
			this.dataObjectToRemove = null
			this.exportsUsingDataObject = null
		},
		onCloseCreateDataObjectModal () {
			this.showCreateDataObjectModal = false
		},
		async onRegisterObject (objectToRegister) {
			const response = await postRegisterDataObject(objectToRegister)
			if (response.data.success) {
				showSnackbar(useLabels(`Successfully registered the object ${objectToRegister.objectName}`))
			}	else {
				showSnackbar({ color: 'red', text: 'There was a problem registering the object ' + objectToRegister.objectName })
			}
			this.showCreateDataObjectModal = false
			this.loadDataObjects()
		}
	}
}
</script>
