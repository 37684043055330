import { configApi } from '../http-client.js'

export const getRegisteredDataObjects = () => {
	return configApi.get('/api/exports/RegisteredDataObjects')
}

export const refreshDataObject = dataObjectId => {
	return configApi.put(`/api/exports/RefreshDataObject/${dataObjectId}`)
}

export const deleteRegisteredDataObject = dataObjectId => {
	return configApi.delete(`/api/exports/DeleteExportRegisteredDataObject/${dataObjectId}`)
}

export const getDataObjectUse = dataObjectId => {
	return configApi.get(`/api/exports/DataObjectUse?objectId=${dataObjectId}`)
}

export const getUnregisteredDataObjects = () => {
	return configApi.get('/api/exports/UnregisteredDataObjects')
}

export const postRegisterDataObject = dataObject => {
	return configApi.post('/api/Exports/RegisterDataObject', dataObject)
}
