<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="searchQuery"
			hide-brand-filter
			:search-query-label="'Data Object Name' | useLabels"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedIsArchivedStatus"
					label="Status"
					:items="statusDropdownItems"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="exporterFullPermissions"
					@click="onCreateClick"
				>
					<v-icon
						left
						dark
					>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Data Exports' | useLabels }}
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredDataExports"
					sort-by="id"
					sort-desc
					@click:row="onEditClick"
				>
					<template #item.enabled="{ item }">
						{{ item.enabled ? 'Yes' : 'No' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="!item.isArchived && exporterFullPermissions"
							tooltip-text="Edit Data Export"
							@click.stop.prevent="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-if="!item.isArchived && exporterFullPermissions"
							tooltip-text="Design Data Export"
							@click.stop.prevent="onDesignClick(item)"
						>
							mdi-pencil-ruler
						</IconButton>
						<IconButton
							v-if="!item.isArchived && exporterFullPermissions"
							tooltip-text="Schedule Data Export"
							@click.stop.prevent="onScheduleClick(item)"
						>
							mdi-calendar-clock
						</IconButton>
						<IconButton
							v-if="!item.isArchived && item.enabled && item.locationId && exporterFullPermissions"
							tooltip-text="Run Export Now"
							@click.stop.prevent="() => (dataExportToRun = item)"
						>
							mdi-play
						</IconButton>
						<IconButton
							v-if="!item.isArchived && !item.pushFileToClient && exporterCanDownloadExport"
							tooltip-text="Download Latest Data Export"
							@click.stop.prevent="validateFileSizeAndDownload(item)"
						>
							mdi-file-download
						</IconButton>
						<IconButton
							v-if="exporterFullPermissions"
							tooltip-text="Clone Data Export"
							@click.stop.prevent="onCloneClick(item)"
						>
							mdi-content-duplicate
						</IconButton>
						<IconButton
							v-else-if="!item.isArchived && exporterReadOnly"
							tooltip-text="View Data Export"
							@click.stop.prevent="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="item.isArchived && exporterFullPermissions"
							tooltip-text="Restore Data Export"
							@click.stop.prevent="() => restoreDataExport(item)"
						>
							mdi-delete-restore
						</IconButton>
						<IconButton
							v-if="!item.isArchived && exporterFullPermissions"
							tooltip-text="Archive Data Export"
							@click.stop.prevent="() => (dataExportToBeRemoved = item)"
						>
							mdi-archive
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ManageDataExportModal
			v-if="showManageDataExportModal"
			:clone-mode="cloneMode"
			:clone-subject-export-id="dataExportToClone"
			:data-export-to-edit="dataExportToEdit"
			:user-full-permissions="exporterFullPermissions"
			:user-read-only="exporterReadOnly"
			@close="closeManageModal"
		/>
		<DesignDataExportModal
			v-if="showDesignDataExportModal"
			:export-id="dataExportToDesign.id"
			@close="closeDesignModal"
		/>
		<ScheduleExportModal
			v-if="showScheduleExportModal && exporterFullPermissions"
			:schedule-to-edit="dataExportToSchedule"
			@close="closeScheduleModal"
		/>
		<!-- Delete Modal -->
		<ConfirmDeleteModal
			v-if="dataExportToBeRemoved"
			:entity-name="dataExportToBeRemoved.name"
			entity-type="Data Export"
			:can-be-recovered="true"
			is-archive
			@close="dataExportToBeRemoved = null"
			@delete="deleteDataExport"
		/>
		<!-- Run Now Modal -->
		<ConfirmationModal
			v-if="dataExportToRun"
			:title-text="`Run Export '${dataExportToRun.name}' Now?` | useLabels"
			@cancel="dataExportToRun = null"
			@confirm="runDataExport"
		>
			<template #modal-content>
				Are you sure you want to run this export '{{ dataExportToRun.name }}' now?
			</template>
		</ConfirmationModal>
		<!-- confirm download modal -->
		<ConfirmationModal
			v-if="dataExportToDownload"
			title-text="Download Latest Data Export"
			confirm-button-text="Download"
			:disable-confirm-button="dataExportToDownload.fileCreatedDate == null"
			@cancel="dataExportToDownload = null"
			@confirm="downloadLatestFile(dataExportToDownload)"
		>
			<template #modal-content>
				<div v-if="dataExportToDownload.fileCreatedDate != null">
					<div>
						File Name: {{ dataExportToDownload.fileName }}
					</div>
					<div>
						File Size: {{ Math.round((convertFileSize(dataExportToDownload.fileSize).convertedFileSize + Number.EPSILON) * 100) / 100 }}
						{{ convertFileSize(dataExportToDownload.fileSize).unit }}
					</div>
					<div>
						Created Date: {{ dataExportToDownload.fileCreatedDate ? format(new Date(dataExportToDownload.fileCreatedDate), DATE_TIME_FORMAT) : '' }}
					</div>
				</div>
				<div v-else>
					<div>
						There is no latest file for this export.
					</div>
				</div>
			</template>
		</ConfirmationModal>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import ManageDataExportModal from './manage-data-export-modal.vue'
import ScheduleExportModal from './schedule-export-modal.vue'
import DesignDataExportModal from './design-data-export-modal.vue'
import ConfirmationModal from '../../../../../shared/components/confirmation-modal.vue'
import { getDataExports, deleteDataExport, restoreDataExport, runDataExport, getLastResultFile, getLastResultFileInfo } from '../../../../../shared/utils/api/data-exports.js'
import { CAN_SEE_EXPORTS_MODULE, EXPORTS_FULL_PERMISSIONS, CAN_DOWNLOAD_LATEST_EXPORT } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { activeExportsLimit, exportsLatestFileSizeLimit } from '../../../../../shared/state/config-keys.js'

export default {
	components: {
		FilterBar,
		Dropdown,
		SectionCard,
		DataTable,
		IconButton,
		ConfirmDeleteModal,
		PrimaryActionButton,
		ManageDataExportModal,
		DesignDataExportModal,
		ScheduleExportModal,
		ConfirmationModal
	},
	setup () {
		return {
			activeExportsLimit,
			format,
			DATE_TIME_FORMAT,
			exportsLatestFileSizeLimit
		}
	},
	data () {
		return {
			dataExports: [],
			searchQuery: null,
			selectedIsArchivedStatus: false,
			showManageDataExportModal: false,
			showDesignDataExportModal: false,
			cloneMode: false,
			dataExportToEdit: null,
			dataExportToDesign: null,
			showScheduleExportModal: false,
			dataExportToSchedule: null,
			dataExportToBeRemoved: null,
			dataExportToRun: null,
			dataExportToClone: null,
			dataExportToDownload: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		exporterReadOnly () {
			return this.productAreaPermission(CAN_SEE_EXPORTS_MODULE)
		},
		exporterFullPermissions () {
			return this.productAreaPermission(EXPORTS_FULL_PERMISSIONS)
		},
		exporterCanDownloadExport () {
			return this.productAreaPermission(CAN_DOWNLOAD_LATEST_EXPORT)
		},
		tableHeaders () {
			const headers = [
				{
					text: 'ID',
					value: 'id',
					width: '5%'
				},
				{
					text: 'Data Export Name',
					value: 'name',
					width: '15%'
				},
				{
					text: 'Enabled',
					value: 'enabled',
					width: '7%'
				},
				{
					text: 'Frequency',
					value: 'frequency',
					width: '10%'
				},
				{
					text: 'Last Export',
					value: 'latestExtractDate',
					width: '10%'
				},
				{
					text: 'Result',
					value: 'result',
					width: '7%'
				},
				{
					text: 'Next Run',
					value: 'nextRunDateFormatted',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '12%',
					sortable: false
				}
			]

			return !this.selectedIsArchivedStatus ? headers : headers.filter(({ value }) => value !== 'nextRunDateFormatted')
		},
		filteredDataExports () {
			return this.dataExports.filter(({ name, isArchived }) => {
				let check = true
				if (this.searchQuery) check = name.toLowerCase().includes(this.searchQuery.toLowerCase())
				if (this.selectedIsArchivedStatus !== null) check = check && isArchived === this.selectedIsArchivedStatus
				return check
			}).map(dataExport => ({
				...dataExport,
				latestExtractDate: dataExport.latestExtractDate === null ? '' : format(new Date(dataExport.latestExtractDate), DATE_TIME_FORMAT),
				nextRunDateFormatted: dataExport.nextRunDate === null ? '' : format(new Date(dataExport.nextRunDate), DATE_TIME_FORMAT)
			}))
		},
		activeExportsLength () {
			return this.dataExports.filter(({ isArchived }) => !isArchived).length
		},
		statusDropdownItems () {
			return [
				{
					text: 'Active',
					value: false
				},
				{
					text: 'Archived',
					value: true
				}
			]
		}
	},
	created () {
		this.loadDataExports()
	},
	methods: {
		async loadDataExports () {
			const { data: { dataExports } } = await getDataExports(0)
			this.dataExports = dataExports
		},
		onCreateClick () {
			const limit = activeExportsLimit.value !== null
			if (limit != null && limit === true && this.activeExportsLength >= activeExportsLimit.value) {
				showSnackbar({
					text: 'Maximum number of active exports has been reached.',
					color: 'red'
				})
			} else {
				this.showManageDataExportModal = true
			}
		},
		onCloneClick (dataExportToClone) {
			this.cloneMode = true
			this.showManageDataExportModal = true
			const exportToClone = {
				...dataExportToClone,
				id: null,
				name: dataExportToClone.name.slice(0, 42) + ' (clone)'
			}
			this.dataExportToEdit = exportToClone
			this.dataExportToClone = dataExportToClone.id
		},
		onEditClick (dataExportToEdit) {
			if (dataExportToEdit.isArchived) return
			this.showManageDataExportModal = true
			this.dataExportToEdit = dataExportToEdit
		},
		onDesignClick (dataExportToDesign) {
			this.showDesignDataExportModal = true
			this.dataExportToDesign = dataExportToDesign
		},
		onScheduleClick (dataExport) {
			this.showScheduleExportModal = true
			this.dataExportToSchedule = dataExport
		},
		convertFileSize (fileSize) {
			let convertedFileSize = fileSize
			let unit = 'B'
			if (fileSize < 1024) {
				unit = 'B'
			} else if (fileSize < 1048576) {
				convertedFileSize = convertedFileSize / 1024
				unit = 'KB'
			} else if (fileSize < 1073741824) {
				convertedFileSize = convertedFileSize / 1024 / 1024
				unit = 'MB'
			} else if (fileSize < 1099511627776) {
				convertedFileSize = convertedFileSize / 1024 / 1024 / 1024
				unit = 'GB'
			}
			return { convertedFileSize, unit }
		},
		async validateFileSizeAndDownload (dataExport) {
			const fileSizeLimit = exportsLatestFileSizeLimit.value
			const { convertedFileSize, unit } = this.convertFileSize(fileSizeLimit)
			await getLastResultFileInfo(dataExport.id).then(response => {
				if (response.fileSize > fileSizeLimit) {
					showSnackbar({
						text: `File exceeds ${convertedFileSize}${unit} limit. Please retrieve this directly from your FTP`,
						color: 'red'
					})
				} else {
					this.dataExportToDownload = dataExport
					this.dataExportToDownload.fileSize = response.fileSize
					this.dataExportToDownload.fileName = response.fileName
					this.dataExportToDownload.fileCreatedDate = response.createdDate
				}
			})
		},
		async downloadLatestFile (dataExport) {
			await getLastResultFile(dataExport.id)
				.then(response => {
					if (!response.fileName) {
						showSnackbar({ text: `Latest file not found for this export`, color: 'red' })
					} else if (!response.fileContent) {
						showSnackbar({ text: `File '${response.fileName}' contains no data, or there was an error retrieving the file`, color: 'red' })
					} else {
						const fileURL = window.URL.createObjectURL(new Blob([response.fileContent]))
						const fileLink = document.createElement('a')
						fileLink.href = fileURL
						fileLink.setAttribute('download', response.fileName)
						document.body.appendChild(fileLink)
						fileLink.click()
						showSnackbar('File located - please check your Downloads')
					}
				})
				.catch(() => {
					showSnackbar({ text: 'Error downloading last result file', color: 'red' })
				})
			this.dataExportToDownload = null
		},
		async runDataExport () {
			await runDataExport(this.dataExportToRun.id)
				.then(() => {
					showSnackbar('Your export has been queued to run now')
					this.dataExportToRun = null
					this.loadDataExports()
				})
				.catch(() => {
					showSnackbar({
						text: 'Error Running Data Export Run',
						color: 'red'
					})
					this.dataExportToRun = null
				})
		},
		async deleteDataExport () {
			await deleteDataExport(this.dataExportToBeRemoved.id)
				.then(() => {
					showSnackbar('Data Export Archived')
					this.dataExports.find(
						item =>
							item.id === this.dataExportToBeRemoved.id
					).isArchived = true
					this.dataExports.find(
						item =>
							item.id === this.dataExportToBeRemoved.id
					).enabled = false
					this.dataExportToBeRemoved = null
				})
				.catch(() => {
					showSnackbar({
						text: 'Error Archiving Data Export',
						color: 'red'
					})
					this.dataExportToBeRemoved = null
				})
		},
		restoreDataExport (dataExport) {
			const limit = activeExportsLimit.value !== null
			if (limit != null && limit === true && this.activeExportsLength >= activeExportsLimit.value) {
				showSnackbar({
					text: 'Maximum number of active exports has been reached.',
					color: 'red'
				})
			} else {
				restoreDataExport(dataExport.id)
					.then(() => {
						showSnackbar('Data Export Restored')
						this.dataExports.find(
							item => item.id === dataExport.id
						).isArchived = false
					})
					.catch(() => {
						showSnackbar({
							text: 'Error Restoring Data Export',
							color: 'red'
						})
					})
			}
		},
		closeManageModal () {
			this.showManageDataExportModal = false
			this.dataExportToEdit = null
			this.cloneMode = false
			this.loadDataExports()
		},
		closeDesignModal () {
			this.showDesignDataExportModal = false
		},
		closeScheduleModal () {
			this.showScheduleExportModal = false
			this.loadDataExports()
		}
	}
}
</script>
