<template>
	<v-btn
		v-bind="$props"
		color="white--text green darken-4"
		class="px-10"
		:class="getQaSelectorClass()"
		v-on="$listeners"
	>
		<slot />
	</v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
	name: 'tertiary-action-button',
	extends: VBtn,
	methods: {
		getQaSelectorClass () {
			const buttonText = this.$slots.default.find(({ text }) => text).text.trim().replace(/\s/g, '-').toLowerCase()
			return `qa-tertiaryactionbutton-${buttonText}`
		}
	}
}
</script>
