<template>
	<ValidationForm
		#default="{ handleSubmit, reset }"
	>
		<Modal width="800px">
			<template #modal-title>
				Schedule {{ scheduleToEdit.name }}
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="6">
							<Dropdown
								v-model="dataExportSchedule.locationId"
								label="Export Output Location *"
								:items="outputLocationItems"
								reserve-tooltip-space
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<Dropdown
								v-model="dataExportSchedule.frequency"
								label="Delivery Frequency *"
								:items="frequencyItems"
								reserve-tooltip-space
								custom-sort
								rules="required"
								@change="onFrequencyChanged(reset)"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-if="dataExportSchedule.frequency === 'Weekly'"
								v-model="dataExportSchedule.dayOfWeek"
								label="Day of Week *"
								custom-sort
								:items="dayOfWeekItems"
								reserve-tooltip-space
								rules="required"
							/>
							<Dropdown
								v-if="dataExportSchedule.frequency === 'Monthly' || dataExportSchedule.frequency === 'Quarterly'"
								v-model="dataExportSchedule.dayOfWeek"
								:label="`On Day ${dataExportSchedule.dayOfWeek} of Month *`"
								:items="dayOfMonthItems"
								reserve-tooltip-space
								custom-sort
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="exportRunTime"
								label="Export Run Time *"
								:rules="{required:true, regex: time24regex}"
								tooltip-text="Enter the time in 24 hour clock format hh:mm"
							/>
						</v-col>
					</v-row>
					<v-row
						dense
					>
						<v-col
							cols="6"
							class="pr-9"
						>
							<DatePicker
								label="Don't Run Before"
								:date="toNonUtcISOString(new Date(dataExportSchedule.startDateFilter)).substring(0, 10)"
								:min="serverTimeInfo.currentServerTime && serverTimeInfo.currentServerTime.substring(0, 10)"
								@update:date="dataExportSchedule.startDateFilter = $event"
							/>
						</v-col>
					</v-row>

					<div v-if="nextRunDate">
						Based on your selections, the next export will be scheduled for:
						<br>
						{{ dateStringWithTimezone(nextRunDate) }}
					</div>
					<div>
						It is currently {{ currentServerTimeFormatted }} in {{ serverTimeInfo.serverTimezone }}.
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(submit)"
				>
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
		<ConfirmDisabledLocationModal
			v-if="confirmDisabledLocation"
			:location-name="selectedLocation.name"
			@cancel="cancel"
			@decline="decline"
			@confirm="enableLocation"
		/>
	</ValidationForm>
</template>
<script>
import { format } from 'date-fns'
import { DATE_TIME_FORMAT_NO_SECONDS } from '../../../../../shared/utils/date-formatting.js'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import Modal from '../../../../../shared/components/modal.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import DatePicker from '../../../../../shared/components/date-picker.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import { getExportLocations, updateExportLocation } from '../../../../../shared/utils/api/export-locations.js'
import { scheduleDataExport } from '../../../../../shared/utils/api/data-exports.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import { getServerTimezoneInfo } from '../../../../../shared/utils/api/server-timezone.js'
import TextField from '../../../../../shared/components/text-field.vue'
import ConfirmDisabledLocationModal from './confirm-disabled-location-modal.vue'

export default {
	components: {
		ValidationForm,
		Modal,
		Dropdown,
		DatePicker,
		PrimaryActionButton,
		SecondaryActionButton,
		TextField,
		ConfirmDisabledLocationModal
	},
	props: {
		scheduleToEdit: Object
	},
	data () {
		return {
			dataExportSchedule: this.prepareScheduleToEdit(),
			exportRunTime: this.setExportRunTime(),
			dontRunBefore: null,
			exportLocations: [],
			time24regex: /^([01]\d|2[0-3]):([0-5]\d)$/,
			serverTimeInfo: {
				currentServerTime: null,
				serverTimezone: null,
				utcOffsent: null
			},
			confirmDisabledLocation: false,
			allowDisabledLocation: false
		}
	},
	computed: {
		outputLocationItems () {
			return this.exportLocations.filter(({ locationType, isArchived }) => isArchived === false)
				.map(({ locationId, name }) => ({ text: name, value: locationId }))
		},
		frequencyItems () {
			return [
				{
					text: 'Daily',
					value: 'Daily'
				},
				{
					text: 'Weekly',
					value: 'Weekly'
				},
				{
					text: 'Monthly',
					value: 'Monthly'
				},
				{
					text: 'Quarterly',
					value: 'Quarterly'
				},
				{
					text: 'One-Off',
					value: 'One-Off'
				}
			]
		},
		dayOfWeekItems () {
			return [
				{
					text: 'Monday',
					value: '1'
				},
				{
					text: 'Tuesday',
					value: '2'
				},
				{
					text: 'Wednesday',
					value: '3'
				},
				{
					text: 'Thursday',
					value: '4'
				},
				{
					text: 'Friday',
					value: '5'
				},
				{
					text: 'Saturday',
					value: '6'
				},
				{
					text: 'Sunday',
					value: '0'
				}
			]
		},
		dayOfMonthItems () {
			return [
				{
					text: '1',
					value: '1'
				},
				{
					text: '2',
					value: '2'
				},
				{
					text: '3',
					value: '3'
				},
				{
					text: '4',
					value: '4'
				},
				{
					text: '5',
					value: '5'
				},
				{
					text: '6',
					value: '6'
				},
				{
					text: '7',
					value: '7'
				},
				{
					text: '8',
					value: '8'
				},
				{
					text: '9',
					value: '9'
				},
				{
					text: '10',
					value: '10'
				},
				{
					text: '11',
					value: '11'
				},
				{
					text: '12',
					value: '12'
				},
				{
					text: '13',
					value: '13'
				},
				{
					text: '14',
					value: '14'
				},
				{
					text: '15',
					value: '15'
				},
				{
					text: '16',
					value: '16'
				},
				{
					text: '17',
					value: '17'
				},
				{
					text: '18',
					value: '18'
				},
				{
					text: '19',
					value: '19'
				},
				{
					text: '20',
					value: '20'
				},
				{
					text: '21',
					value: '21'
				},
				{
					text: '22',
					value: '22'
				},
				{
					text: '23',
					value: '23'
				},
				{
					text: '24',
					value: '24'
				},
				{
					text: '25',
					value: '25'
				},
				{
					text: '26',
					value: '26'
				},
				{
					text: '27',
					value: '27'
				},
				{
					text: '28',
					value: '28'
				}
			]
		},
		nextRunDate () {
			const regex = new RegExp(this.time24regex)
			const exportRunTimeValid = regex.test(this.exportRunTime)
			if (!exportRunTimeValid) return ''
			const serverDate = new Date(new Date(this.serverTimeInfo.currentServerTime).toDateString())
			const minRunDate = new Date(new Date(this.dataExportSchedule.startDateFilter).toDateString())
			const dateToCompare = minRunDate > serverDate ? minRunDate : serverDate
			const exportRunTimeHour = parseInt(this.exportRunTime.substring(0, 2))
			const exportRunTimeMins = parseInt(this.exportRunTime.substring(3, 5))
			if (this.dataExportSchedule.frequency === 'One-Off' || this.dataExportSchedule.frequency === 'Daily') {
				return this.currentServerTime > new Date(serverDate.setHours(exportRunTimeHour, exportRunTimeMins)).getTime()
					? new Date(new Date(dateToCompare.setHours(exportRunTimeHour,	exportRunTimeMins)).setDate(dateToCompare.getDate() + 1))
					:	new Date(dateToCompare.setHours(exportRunTimeHour, exportRunTimeMins))
			}
			const dayOfWeekValid = this.dataExportSchedule.dayOfWeek !== ''
			if (this.dataExportSchedule.frequency === 'Weekly' && dayOfWeekValid) {
				let dayDiff = (this.dataExportSchedule.dayOfWeek - dateToCompare.getDay() < 0)
					? this.dataExportSchedule.dayOfWeek - dateToCompare.getDay() + 7
					: this.dataExportSchedule.dayOfWeek - dateToCompare.getDay()
				// Add a week if you picked 'today' but a time in the past
				if (this.currentServerTime > new Date(serverDate.setHours(exportRunTimeHour, exportRunTimeMins)).getTime() && minRunDate < serverDate && dayDiff === 0) {
					dayDiff = dayDiff + 7
				}
				const weekDate = new Date(dateToCompare.setDate(dateToCompare.getDate() + dayDiff))
				const weekDateTime = new Date(weekDate.setHours(exportRunTimeHour, exportRunTimeMins))
				return new Date(weekDateTime.setHours(exportRunTimeHour, exportRunTimeMins))
			}
			if (this.dataExportSchedule.frequency === 'Monthly' && dayOfWeekValid) {
				const targetDateTime = new Date(new Date(dateToCompare.getFullYear(), dateToCompare.getMonth(),
					this.dataExportSchedule.dayOfWeek).setHours(exportRunTimeHour, exportRunTimeMins))
				if (targetDateTime.getTime() > this.currentServerTime && targetDateTime.getTime() > minRunDate.getTime()) {
					return targetDateTime
				} else {
					const dayDiff = this.dataExportSchedule.dayOfWeek - dateToCompare.getDate()
					return dayDiff < 0 || (dayDiff === 0 && new Date(serverDate.setHours(exportRunTimeHour, exportRunTimeMins)).getTime() < this.currentServerTime)
						? new Date(new Date(new Date(dateToCompare.setHours(exportRunTimeHour,
							exportRunTimeMins)).setMonth(dateToCompare.getMonth() + 1)).setDate(this.dataExportSchedule.dayOfWeek))
						: new Date(new Date(dateToCompare.setHours(exportRunTimeHour, exportRunTimeMins)).setDate(this.dataExportSchedule.dayOfWeek))
				}
			}
			if (this.dataExportSchedule.frequency === 'Quarterly' && dayOfWeekValid) {
				const q1 = new Date(new Date(dateToCompare.getFullYear(), 0, this.dataExportSchedule.dayOfWeek).setHours(exportRunTimeHour, exportRunTimeMins))
				const q2 = new Date(new Date(dateToCompare.getFullYear(), 3, this.dataExportSchedule.dayOfWeek).setHours(exportRunTimeHour, exportRunTimeMins))
				const q3 = new Date(new Date(dateToCompare.getFullYear(), 6, this.dataExportSchedule.dayOfWeek).setHours(exportRunTimeHour, exportRunTimeMins))
				const q4 = new Date(new Date(dateToCompare.getFullYear(), 9, this.dataExportSchedule.dayOfWeek).setHours(exportRunTimeHour, exportRunTimeMins))
				if (dateToCompare.getTime() > q1.getTime() && dateToCompare.getTime() < q2.getTime())	return q2
				if (dateToCompare.getTime() > q2.getTime() && dateToCompare.getTime() < q3.getTime())	return q3
				if (dateToCompare.getTime() > q3.getTime() && dateToCompare.getTime() < q4.getTime())	return q4
				else return new Date(q1.setFullYear(q1.getFullYear() + 1))
			} else return ''
		},
		currentServerTime () {
			return new Date(this.serverTimeInfo?.currentServerTime).getTime()
		},
		currentServerTimeFormatted () {
			return format(new Date(new Date(this.serverTimeInfo?.currentServerTime).getTime()), DATE_TIME_FORMAT_NO_SECONDS)
		},
		selectedLocation () {
			return this.dataExportSchedule.locationId != null ? this.exportLocations.find(({ locationId }) => locationId === this.dataExportSchedule.locationId) : null
		}
	},
	async created () {
		this.getExportLocations()
		const timezoneResponse = await getServerTimezoneInfo()
		this.serverTimeInfo = timezoneResponse.data
		if (!this.dataExportSchedule.startDateFilter || this.dataExportSchedule.startDateFilter < this.serverTimeInfo.currentServerTime) {
			this.dataExportSchedule.startDateFilter = this.serverTimeInfo.currentServerTime
		}
	},
	methods: {
		async getExportLocations () {
			this.exportLocations = await getExportLocations()
		},
		async submit () {
			// Check if location is enabled
			if (!this.selectedLocation.enabled && !this.allowDisabledLocation) {
				this.confirmDisabledLocation = true
				return
			}
			const offset = this.nextRunDate.getTimezoneOffset()
			const nextRunDate = this.toNonUtcISOString(new Date(this.nextRunDate.getTime() + -(offset * 60000)))
			const startDateFilter = this.toNonUtcISOString(new Date(new Date(this.dataExportSchedule.startDateFilter).getTime() + -(offset * 60000)))
			const scheduledExport = {
				id: this.dataExportSchedule.id,
				locationId: this.dataExportSchedule.locationId,
				frequency: this.dataExportSchedule.frequency,
				dayOfWeek: this.dataExportSchedule.frequency === 'Weekly'
					? this.dayOfWeekItems.find(({ value }) => value === this.dataExportSchedule.dayOfWeek).text
					: this.dataExportSchedule.dayOfWeek,
				nextRunDate: nextRunDate,
				startDateFilter: startDateFilter,
				endDateFilter: null
			}
			await scheduleDataExport(scheduledExport)
				.then(() => {
					showSnackbar('Data Export schedule updated')
					this.$emit('close')
				})
				.catch(({ response }) => {
					if (response) {
						if (response.status === 400) {
							showSnackbar({ text: response.data[0], color: 'red' })
						}	else {
							showSnackbar({ text: 'Error updating Data Export schedule', color: 'red' })
						}
					}
				})
		},
		dateStringWithTimezone (dateToConvert) {
			const date = new Date(dateToConvert)
			const formattedDate = format(new Date(date.getTime()), DATE_TIME_FORMAT_NO_SECONDS)
			return formattedDate + ' ' + this.serverTimeInfo.serverTimezone
		},
		onFrequencyChanged (reset) {
			reset()
			this.dataExportSchedule.dayOfWeek = ''
			if (this.dataExportSchedule.frequency === 'One-Off') {
				const serverTime = new Date(this.currentServerTime)
				this.exportRunTime = serverTime.getHours().toString().padStart(2, '0') + ':' + serverTime.getMinutes().toString().padStart(2, '0')
			}
		},
		prepareScheduleToEdit () {
			const schedule = JSON.parse(JSON.stringify(this.scheduleToEdit))
			if (schedule.frequency && schedule.dayOfWeek) {
				if (schedule.frequency.toLowerCase() === 'weekly') {
					switch (schedule.dayOfWeek.toLowerCase()) {
						case 'sunday':
							schedule.dayOfWeek = '0'
							break
						case 'monday':
							schedule.dayOfWeek = '1'
							break
						case 'tuesday':
							schedule.dayOfWeek = '2'
							break
						case 'wednesday':
							schedule.dayOfWeek = '3'
							break
						case 'thursday':
							schedule.dayOfWeek = '4'
							break
						case 'friday':
							schedule.dayOfWeek = '5'
							break
						case 'saturday':
							schedule.dayOfWeek = '6'
							break
						default:
							schedule.dayOfWeek = null
					}
				}
			}
			return schedule
		},
		setExportRunTime () {
			if (this.scheduleToEdit.nextRunDate) {
				const storedDate = new Date(this.scheduleToEdit.nextRunDate)
				return storedDate.getHours().toString().padStart(2, '0') + ':' + storedDate.getMinutes().toString().padStart(2, '0')
			}
			return null
		},
		toNonUtcISOString (dateTime) {
			const dateString = dateTime.toISOString()
			return dateString.replace('Z', '')
		},
		cancel () {
			this.confirmDisabledLocation = false
			this.allowDisabledLocation = false
		},
		decline () {
			this.confirmDisabledLocation = false
			this.allowDisabledLocation = true
			this.submit()
		},
		async enableLocation () {
			this.allowDisabledLocation = false
			const location = {
				confirmationEmailId: this.selectedLocation.confirmationEmailId,
				description: this.selectedLocation.description,
				emailsToNotify: this.selectedLocation.emailsToNotify,
				enabled: true,
				fileMask: this.selectedLocation.fileMask,
				hostKey: this.selectedLocation.hostKey,
				hostName: this.selectedLocation.hostName,
				locationId: this.selectedLocation.locationId,
				locationType: this.selectedLocation.locationType + '',
				login: this.selectedLocation.login,
				name: this.selectedLocation.name,
				outputFolder: this.selectedLocation.outputFolder,
				password: this.selectedLocation.password,
				portNumber: this.selectedLocation.portNumber,
				zipFileMask: this.selectedLocation.zipFileMask
			}
			let updateSuccessful = false
			await updateExportLocation(location)
				.then(() => {
					showSnackbar('Export Location Enabled')
					updateSuccessful = true
				})
				.catch(() =>
					showSnackbar({
						text: 'Error Enabling Export Location',
						color: 'red'
					})
				)
			if (updateSuccessful) {
				await this.getExportLocations()
					.finally(() => {
						this.dataExportSchedule.locationId = location.locationId
						this.confirmDisabledLocation = false
					})
				await this.submit()
			}
		}
	}
}
</script>
