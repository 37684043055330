import { configApi } from '../http-client.js'

export const getExportLocations = () => {
	return configApi.get('/api/ExportLocations').then(({ data }) => data)
}
export const deleteExportLocations = id => {
	return configApi.delete('/api/ExportLocations/' + id).then(({ data }) => data)
}
export const restoreExportLocations = id => {
	return configApi.put('/api/ExportLocations/Restore/' + id).then(({ data }) => data)
}
export const createExportLocation = exportLocation => {
	return configApi.post('/api/ExportLocations', { exportLocations: [exportLocation] })
}
export const updateExportLocation = exportLocation => {
	return configApi.put('/api/ExportLocations', exportLocation)
}
