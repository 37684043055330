var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cassie-vertical-md pt-2"},[_c('FilterBar',{attrs:{"search-query":_vm.searchQuery,"hide-brand-filter":"","search-query-label":_vm._f("useLabels")('Export Location Name')},on:{"update:searchQuery":function($event){_vm.searchQuery=$event},"update:search-query":function($event){_vm.searchQuery=$event},"persistSearchQuery":function (e) { return (_vm.searchQuery = e); }},scopedSlots:_vm._u([{key:"after-filters",fn:function(){return [_c('Dropdown',{attrs:{"label":_vm._f("useLabels")('Filter Active'),"items":[
					{
						value: true,
						text: 'Active',
					},
					{
						value: false,
						text: 'Archived',
					} ]},model:{value:(_vm.filterActive),callback:function ($$v) {_vm.filterActive=$$v},expression:"filterActive"}})]},proxy:true},{key:"action",fn:function(){return [(_vm.userFullPermissions)?_c('PrimaryActionButton',{on:{"click":_vm.openCreate}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1):_vm._e()]},proxy:true}])}),_c('SectionCard',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm._f("useLabels")("Export Locations"))+" ")]},proxy:true},{key:"body",fn:function(){return [_c('DataTable',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredExportLocations,"sort-by":"locationId","sort-desc":""},on:{"click:row":function (e) { return _vm.populateModal(e); }},scopedSlots:_vm._u([{key:"item.enabled",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.enabled ? "Yes" : "No")+" ")]}},{key:"item.createdDate",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(new Date(item.createdDate), _vm.DATE_FORMAT))+" ")]}},{key:"item.lastTestDate",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastTestDate === null ? "" : _vm.format(new Date(item.lastTestDate), _vm.DATE_FORMAT))+" ")]}},{key:"item.action",fn:function(ref){
				var item = ref.item;
return [_c('div',[(_vm.userFullPermissions && !item.isArchived)?_c('IconButton',{attrs:{"tooltip-text":"Edit Export Location"},on:{"click":function($event){$event.stopPropagation();return _vm.populateModal(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.userFullPermissions)?_c('IconButton',{attrs:{"tooltip-text":"Clone Export Location"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.clone(item); }).apply(null, arguments)}}},[_vm._v(" mdi-content-duplicate ")]):_vm._e(),(_vm.userFullPermissions && !item.isArchived)?_c('IconButton',{attrs:{"tooltip-text":"Archive Export Location"},on:{"click":function($event){$event.stopPropagation();return (function () { return (_vm.exportLocationToBeRemoved = item); }).apply(null, arguments)}}},[_vm._v(" mdi-archive ")]):_vm._e()],1),(item.isArchived)?[(_vm.userFullPermissions)?_c('IconButton',{attrs:{"tooltip-text":"Restore Export Location"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.restoreLocation(item); }).apply(null, arguments)}}},[_vm._v(" mdi-delete-restore ")]):_vm._e()]:_vm._e(),(!_vm.userFullPermissions)?_c('IconButton',{attrs:{"tooltip-text":"View Export Location"},on:{"click":function($event){$event.stopPropagation();return _vm.populateModal(item)}}},[_vm._v(" mdi-eye ")]):_vm._e()]}}])})]},proxy:true}])}),(_vm.showModal)?_c('ExportLocationsModal',{attrs:{"edit-location":_vm.editLocation,"system-emails":_vm.systemEmails},on:{"close":_vm.close,"save":_vm.save}}):_vm._e(),(_vm.exportLocationToBeRemoved)?_c('ConfirmDeleteModal',{attrs:{"entity-name":_vm.exportLocationToBeRemoved.name,"entity-type":"Export Location","can-be-recovered":true,"is-archive":""},on:{"close":function($event){_vm.exportLocationToBeRemoved = null},"delete":_vm.deleteExportLocation}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }