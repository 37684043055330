<template>
	<ValidationForm
		#default="{ handleSubmit }"
	>
		<Modal
			width="50%"
		>
			<template #modal-title>
				Create Data Object
				<v-spacer />
				<IconButton
					@click="closeModal"
				>
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<div
					class="cassie-vertical-md"
				>
					<div class="d-flex flex-row">
						<Dropdown
							v-model="formData.objectToRegister"
							:label="'Object To Register *' | useLabels"
							class="cassie-input-width-xl"
							:items="unregisteredDataObjects"
							rules="required"
						/>
					</div>
					<div class="d-flex flex-row">
						<TextField
							v-model="formData.name"
							class="cassie-input-width-xl"
							label="Name *"
							:rules="{required: true, max: 50}"
						/>
					</div>
					<div class="d-flex flex-row">
						<TextArea
							v-model="formData.description"
							class="cassie-input-width-xl"
							label="Description *"
							:rules="{required: true, max: 500}"
						/>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton
					class="action-button"
					@click="closeModal"
				>
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					class="action-button"
					@click="handleSubmit(handleClick)"
				>
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import Modal from '../../../../../shared/components/modal.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import TextArea from '../../../../../shared/components/textarea.vue'
import ValidationForm from '../../../../../shared/components/validation-form.vue'
import { waitForLoad } from '../../../../../shared/state/brands.js'
import { getUnregisteredDataObjects } from '../../../../../shared/utils/api/data-objects.js'
export default {
	components: {
		Modal,
		IconButton,
		PrimaryActionButton,
		SecondaryActionButton,
		Dropdown,
		TextField,
		TextArea,
		ValidationForm
	},
	setup () {
		return {
		}
	},
	data () {
		return {
			formData: {
				objectToRegister: '',
				name: '',
				description: '',
				lakeName: ''
			},
			responseData: [],
			unregisteredDataObjects: []
		}
	},
	async created () {
		await waitForLoad()
		await getUnregisteredDataObjects().then(response => {
			this.responseData = JSON.parse(JSON.stringify(response.data.unregisteredDataObjects))
			this.responseData.forEach(item => {
				this.unregisteredDataObjects.push({ value: item.id, text: item.name })
			})
		})
	},
	methods: {
		closeModal () {
			this.$emit('close')
		},
		async handleClick () {
			const dataObject = this.responseData.find(m => m.id === this.formData.objectToRegister)
			const dataObjectToRegister = {
				displayName: this.formData.name,
				objectSchema: dataObject.schema,
				objectName: dataObject.name,
				description: this.formData.description,
				lakeName: this.formData.lakeName
			}
			this.$emit('register-object', dataObjectToRegister)
		}
	}
}
</script>
