<template>
	<DataExportLayout>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<DataExportsTab />
				</template>
				<template #1>
					<ExportLocationTab />
				</template>
				<template #2>
					<DataObjectsTab />
				</template>
			</TabItems>
		</template>
	</DataExportLayout>
</template>

<script>
import Tabs from '../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../shared/components/tab-items.vue'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import DataExportLayout from './data-exports-layout.vue'
import DataExportsTab from './data-exports-tab.vue'
import ExportLocationTab from './export-locations-tab.vue'
import DataObjectsTab from './data-objects-tab.vue'
export default {
	components: { Tabs, TabItems, DataExportLayout, DataExportsTab, ExportLocationTab, DataObjectsTab },
	props: {
		accessedVia: String,
		previouslySelectedLanguage: Number
	},
	data () {
		return {
			tabs: [
				{ title: useLabels('Data Exports'), slot: '0' },
				{ title: useLabels('Export Locations'), slot: '1' },
				{ title: useLabels('Data Objects'), slot: '2' }
			],
			tab: '0',
			statements: [],
			languages: []
		}
	}
}
</script>
