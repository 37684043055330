import { configApi } from '../http-client.js'

export const getDataExportDesign = (exportId, objectId) => {
	return objectId ? configApi.get(`api/Exports/GetDataExportDesign?exportId=${exportId}&objectId=${objectId}`) : configApi.get(`api/Exports/GetDataExportDesign?exportId=${exportId}`)
}

export const createDataExportDesign = design => {
	return configApi.post('/api/Exports/CreateDataExportDesign', design)
}

export const updateDataExportDesign = design => {
	return configApi.put('/api/Exports/UpdateDataExportDesign', design)
}

export const getDataExports = exportId => {
	return configApi.get(`/api/Exports?exportId=${exportId}`)
}

export const getDataExportHelperValues = () => {
	return configApi.get('/api/Exports/ExportConfigHelperValues')
}

export const postDataExport = dataExport => {
	return configApi.post('/api/Exports', dataExport)
}

export const putDataExport = dataExport => {
	return configApi.put('/api/Exports', dataExport)
}

export const scheduleDataExport = scheduledDataExport => {
	return configApi.put('/api/Exports/DataExportSchedule', scheduledDataExport)
}

export const deleteDataExport = id => {
	return configApi.delete(`/api/Exports?exportId=${id}`).then(({ data }) => data)
}

export const restoreDataExport = id => {
	return configApi.put('/api/Exports/Restore/' + id).then(({ data }) => data)
}

export const runDataExport = id => {
	return configApi.post(`/api/Exports/ExecuteNow?exportId=${id}`).then(({ data }) => data)
}

export const getDataExportPreview = exportId => configApi.get(`api/Exports/PreviewExport/${exportId}`, { responseType: 'blob' }).then(response => ({
	fileContent: response.data,
	fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0]
}))

export const getLastResultFile = exportId => configApi.get(`/api/Exports/GetLastResultFile/${exportId}`, { responseType: 'blob' }).then(response => ({
	fileContent: response.data,
	fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0]
}))

export const getLastResultFileInfo = exportId => configApi.get(`/api/Exports/GetLastResultFileInfo?exportId=${exportId}`).then(response => ({
	fileSize: response.data.fileSize,
	fileName: response.data.fileName,
	createdDate: response.data.createdDate,
	currentStatus: response.data.currentStatus
}))
