<template>
	<Modal width="500px">
		<template #modal-title>
			{{ titleText }}
		</template>
		<template #modal-content>
			<slot name="modal-content" />
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('cancel')">
				Cancel
			</SecondaryActionButton>
			<SecondaryActionButton
				v-if="!disableConfirmButton"
				@click="$emit('confirm')"
			>
				{{ confirmButtonText }}
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from './modal.vue'
import SecondaryActionButton from './secondary-action-button.vue'
export default {
	components: { SecondaryActionButton, Modal },
	props: {
		titleText: String,
		confirmButtonText: {
			type: String,
			default: 'Confirm'
		},
		disableConfirmButton: Boolean
	}
}
</script>
