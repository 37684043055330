<template>
	<div class="cassie-vertical-md pt-2">
		<FilterBar
			:search-query.sync="searchQuery"
			hide-brand-filter
			:search-query-label="'Export Location Name' | useLabels"
			@persistSearchQuery="(e) => (searchQuery = e)"
		>
			<template #after-filters>
				<Dropdown
					v-model="filterActive"
					:label="'Filter Active' | useLabels"
					:items="[
						{
							value: true,
							text: 'Active',
						},
						{
							value: false,
							text: 'Archived',
						},
					]"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="userFullPermissions"
					@click="openCreate"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ "Export Locations" | useLabels }}
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredExportLocations"
					sort-by="locationId"
					sort-desc
					@click:row="(e) => populateModal(e)"
				>
					<template #item.enabled="{ item }">
						{{ item.enabled ? "Yes" : "No" }}
					</template>
					<template #item.createdDate="{ item }">
						{{ format(new Date(item.createdDate), DATE_FORMAT) }}
					</template>
					<template #item.lastTestDate="{ item }">
						{{ item.lastTestDate === null ? "" : format(new Date(item.lastTestDate), DATE_FORMAT) }}
					</template>
					<template #item.action="{ item }">
						<div>
							<IconButton
								v-if="userFullPermissions && !item.isArchived"
								tooltip-text="Edit Export Location"
								@click.stop="populateModal(item)"
							>
								mdi-pencil
							</IconButton>
							<IconButton
								v-if="userFullPermissions"
								tooltip-text="Clone Export Location"
								@click.stop="() => clone(item)"
							>
								mdi-content-duplicate
							</IconButton>
							<IconButton
								v-if="userFullPermissions && !item.isArchived"
								tooltip-text="Archive Export Location"
								@click.stop="() => (exportLocationToBeRemoved = item)"
							>
								mdi-archive
							</IconButton>
						</div>

						<template v-if="item.isArchived">
							<IconButton
								v-if="userFullPermissions"
								tooltip-text="Restore Export Location"
								@click.stop="() => restoreLocation(item)"
							>
								mdi-delete-restore
							</IconButton>
						</template>
						<IconButton
							v-if="!userFullPermissions"
							tooltip-text="View Export Location"
							@click.stop="populateModal(item)"
						>
							mdi-eye
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ExportLocationsModal
			v-if="showModal"
			:edit-location="editLocation"
			:system-emails="systemEmails"
			@close="close"
			@save="save"
		/>
		<!-- Delete Modal -->
		<ConfirmDeleteModal
			v-if="exportLocationToBeRemoved"
			:entity-name="exportLocationToBeRemoved.name"
			entity-type="Export Location"
			:can-be-recovered="true"
			is-archive
			@close="exportLocationToBeRemoved = null"
			@delete="deleteExportLocation"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { format } from 'date-fns'
import { EXPORTS_FULL_PERMISSIONS } from '../../../../../shared/permissions/admin-portal-permissions.js'
import { DATE_FORMAT } from '../../../../../shared/utils/date-formatting.js'
import { getSystemFromEmails } from '../../../../../shared/utils/api/system-emails.js'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import Dropdown from '../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../shared/components/data-table.vue'
import ConfirmDeleteModal from '../../../../../shared/components/confirm-delete-modal.vue'
import {
	getExportLocations,
	createExportLocation,
	updateExportLocation,
	deleteExportLocations,
	restoreExportLocations
} from '../../../../../shared/utils/api/export-locations.js'
import { useLabels } from '../../../../../shared/state/admin-portal-navigation.js'
import { showSnackbar } from '../../../../../shared/state/snackbar.js'
import ExportLocationsModal from './export-locations-modal.vue'
export default {
	components: {
		FilterBar,
		Dropdown,
		SectionCard,
		DataTable,
		IconButton,
		ConfirmDeleteModal,
		ExportLocationsModal,
		PrimaryActionButton
	},
	props: {
		statements: {
			type: Array,
			default: () => []
		}
	},
	setup () {
		return {
			format,
			DATE_FORMAT
		}
	},
	data () {
		return {
			exportLocations: [],
			state: {
				inputEmail: ''
			},
			exportLocationToBeRemoved: null,
			systemEmails: [],
			editLocation: null,
			filterActive: true,
			searchQuery: '',
			showModal: false,
			modalForm: {
				name: '',
				enabled: true,
				description: '',
				exportFolder: '',
				systemEmail: null,
				exportConfirmationEmails: [],
				lastTestDate: null,
				lastTestResult: null,
				textFileMask: '@YYYY@_@MO@_@DD@_@HH@@MM@@SS@_EXPORT@NNNNN@.TXT',
				zipFileMask: '@YYYY@_@MO@_@DD@_@HH@@MM@@SS@_EXPORT@NNNNN@.ZIP',
				locationType: null
			}
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		tableHeaders () {
			const tableHeaders = [
				{
					text: 'ID',
					value: 'locationId',
					width: '5%'
				},
				{
					text: useLabels('Name'),
					value: 'name',
					width: '45%'
				},
				{
					text: 'Enabled',
					value: 'enabled',
					width: '15%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '20%'
				},
				{
					text: 'Action',
					value: 'action',
					sortable: false,
					width: '15%'
				}
			]
			return tableHeaders
		},
		isEdit () {
			return this.editLocation !== null
		},
		filteredExportLocations () {
			return this.exportLocations.filter(item => {
				if (
					this.filterActive !== null &&
          item.isArchived === this.filterActive
				) {
					return false
				}
				return item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
			}).map(item => {
				return item
			})
		},
		apiCreateModel () {
			return {
				name: this.modalForm.name,
				description: this.modalForm.description,
				enabled: this.modalForm.enabled,
				locationType: this.modalForm.locationType + '',
				fileMask: this.modalForm.textFileMask,
				zipFileMask: this.modalForm.zipFileMask,
				outputFolder: this.modalForm.exportFolder,
				emailsToNotify: this.modalForm.exportConfirmationEmails.join(';'),
				confirmationEmailId: this.modalForm.systemEmail
			}
		},
		userFullPermissions () {
			return this.productAreaPermission(EXPORTS_FULL_PERMISSIONS)
		}
	},

	async created () {
		const [exportLocations, systemEmails] = await Promise.all([
			getExportLocations(),
			getSystemFromEmails()
		])

		this.exportLocations = exportLocations
		this.systemEmails = systemEmails
	},
	methods: {
		populateModal (item) {
			this.editLocation = item
			this.showModal = true
		},
		async deleteExportLocation () {
			await deleteExportLocations(this.exportLocationToBeRemoved.locationId)
				.then(() => {
					showSnackbar('Export Location Archived')
					this.exportLocations.find(
						item =>
							item.locationId === this.exportLocationToBeRemoved.locationId
					).isArchived = true
					this.exportLocations.find(
						item =>
							item.locationId === this.exportLocationToBeRemoved.locationId
					).enabled = false
					this.exportLocationToBeRemoved = null
				})
				.catch(() => {
					showSnackbar({
						text: 'Error Archiving Export Location',
						color: 'red'
					})
					this.exportLocationToBeRemoved = null
				})
		},
		restoreLocation (location) {
			restoreExportLocations(location.locationId)
				.then(() => {
					showSnackbar('Export Location Restored')
					this.exportLocations.find(
						item => item.locationId === location.locationId
					).isArchived = false
				})
				.catch(() => {
					showSnackbar({
						text: 'Error Restoring Export Location',
						color: 'red'
					})
				})
		},
		openCreate () {
			this.editLocation = null
			this.modalForm.name = ''
			this.modalForm.description = ''
			this.modalForm.enabled = true
			this.modalForm.locationType = null
			this.modalForm.textFileMask = ''
			this.modalForm.zipFileMask = ''
			this.modalForm.exportFolder = ''
			this.modalForm.exportConfirmationEmails = []
			this.modalForm.systemEmail = null
			this.showModal = true
		},
		async clone (location) {
			const clonedLocation = { ...location }
			clonedLocation.locationId = null
			clonedLocation.name = location.name + ' (Clone)'
			this.editLocation = clonedLocation
			this.showModal = true
		},
		async save (model) {
			this.showModal = false
			if (model.locationId) {
				await updateExportLocation(model)
					.then(() => {
						showSnackbar('Export Location Updated')
					})
					.catch(() =>
						showSnackbar({
							text: 'Error Updating Export Location',
							color: 'red'
						})
					)
			} else {
				await createExportLocation(model)
					.then(() => {
						showSnackbar('Export Location Created')
					})
					.catch(() =>
						showSnackbar({
							text: 'Error Creating Export Location',
							color: 'red'
						})
					)
			}
			this.exportLocations = await getExportLocations()
		},
		close () {
			this.showModal = false
		}
	}
}
</script>
