<template>
	<v-dialog
		v-model="modal"
		width="290px"
	>
		<template #activator="{ on, attrs }">
			<TextField
				:value="displayDate || date"
				:disabled="disabled"
				:small="small"
				append-icon="mdi-calendar"
				readonly
				:label="label"
				:rules="{required: requiredDate}"
				v-bind="attrs"
				v-on="on"
			/>
		</template>
		<v-date-picker
			:value="date"
			scrollable
			:min="min"
			:max="max"
			@input="value => {
				$emit('update:date', value)
				modal = false
			}"
		/>
	</v-dialog>
</template>

<script>
import TextField from './text-field.vue'
export default {
	components: { TextField },
	props: {
		label: {
			type: String
		},
		date: {
			type: String
		},
		min: {
			type: String
		},
		max: {
			type: String
		},
		displayDate: {
			type: String
		},
		disabled: {
			type: Boolean
		},
		small: {
			type: Boolean
		},
		requiredDate: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			modal: false
		}
	}
}
</script>
