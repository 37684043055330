<template>
	<ValidationForm #default="{handleSubmit}">
		<Modal width="700px">
			<template #modal-title>
				Field Filter
			</template>
			<template #modal-content>
				<div
					class="cassie-horizontal-sm d-flex flex-column"
				>
					<Dropdown
						v-model="query.operator"
						:label="'Operator' | useLabels"
						:items="operatorItems"
						class="pb-4"
						@change="onOperatorChanged($event)"
					/>
					<template
						v-if="typeToDisplay === 'number'"
						class="d-flex flex-column"
					>
						<div
							v-for="(value, index) in query.values"
							:key="index"
							class="d-flex flex-row pb-3"
						>
							<TextField
								v-model="query.values[index].value"
								:label="('Value ' + [index+1])"
								class="cassie-input-width-md"
								rules="required"
								type="number"
								@change="countCharactersUsed"
								@keyup="countCharactersUsed"
							/>
							<IconButton
								v-if="index > 0 || query.values.length > 1"
								@click="removeField(index)"
							>
								mdi-trash-can
							</IconButton>
						</div>
						<div
							v-if="showAddFieldButton"
							class="pb-3"
						>
							<SecondaryActionButton
								:disabled="filterMaxLengthReached"
								@click="addField"
							>
								Add Field
							</SecondaryActionButton>
						</div>
					</template>
					<template
						v-if="typeToDisplay === 'string'"
						class="input-field"
					>
						<TextField
							v-model="query.values[0].value"
							:label="'Value' | useLabels"
							class="cassie-input-width-lg pb-3"
							rules="required"
							type="text"
							@change="countCharactersUsed"
							@keyup="countCharactersUsed"
						/>
					</template>
					<template
						v-if="typeToDisplay === 'date'"
						class="d-flex flex-column"
					>
						<div
							class="cassie-input-width-md"
							:class="{ 'pb-3': query.operator === 5 }"
						>
							<DatePicker
								:date="query.values[0].value"
								required-date
								@update:date="query.values[0].value = $event"
							/>
						</div>
						<div class="cassie-input-width-md">
							<DatePicker
								v-if="query.operator === 5"
								:date="query.values[1].value"
								:min="query.values[0].value"
								required-date
								@update:date="query.values[1].value = $event"
							/>
						</div>
					</template>
					<template
						v-if="typeToDisplay === 'bit'"
						class="input-field"
					>
						<Dropdown
							v-model="query.values[0].value"
							:label="'Value' | useLabels"
							:items="bitItemValues"
							class="cassie-input-width-sm"
							rules="required"
						/>
					</template>
					<div
						v-if="query.operator && (typeToDisplay === 'number' || typeToDisplay === 'string')"
						class="d-flex"
					>
						<div>
							Filter characters used: {{ characterCount }} of 500.
						</div>
						<div
							v-if="filterMaxLengthReached"
							class="pl-1"
						>
							Too many characters. Please reduce the character count.
						</div>
					</div>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('cancel')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					:disabled="filterMaxLengthReached"
					@click="handleSubmit(confirmFilter)"
				>
					confirm
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>

<script>
import Dropdown from './dropdown.vue'
import TextField from './text-field.vue'
import DatePicker from './date-picker.vue'
import SecondaryActionButton from './secondary-action-button.vue'
import PrimaryActionButton from './primary-action-button.vue'
import Modal from './modal.vue'
import ValidationForm from './validation-form.vue'
import IconButton from '../../shared/components/icon-button.vue'

const numberItems = [
	{ value: 1, text: 'equal to' },
	{ value: 2, text: 'not equal to' },
	{ value: 3, text: 'less than' },
	{ value: 4, text: 'greater than' },
	{ value: 5, text: 'between' }
]

const stringItems = [
	{ value: 1, text: 'equal to' }
]

const dateItems = [
	{ value: 1, text: 'equal to' },
	{ value: 5, text: 'between' }
]

const bitItems = [
	{ value: 1, text: 'equal to' }
]

const typeLookup = {
	int: 'number',
	bigint: 'number',
	decimal: 'number',
	money: 'number',
	numeric: 'number',
	float: 'number',
	real: 'number',
	char: 'string',
	nchar: 'string',
	nvarchar: 'string',
	varchar: 'string',
	uniqueidentifier: 'string',
	date: 'date',
	datetime: 'date',
	bit: 'bit'
}

export default {
	name: 'query-builder-modal',
	components: {
		Dropdown,
		TextField,
		DatePicker,
		SecondaryActionButton,
		PrimaryActionButton,
		Modal,
		ValidationForm,
		IconButton
	},
	props: {
		queryBuilderItem: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			query: {
				operator: null,
				operatorText: '',
				values: [{ value: null }]
			},
			showAddFieldButton: false,
			characterCount: 0,
			filterMaxLengthReached: false
		}
	},
	computed: {
		typeToDisplay () {
			return typeLookup[this.queryBuilderItem.dataType]
		},
		bitItemValues () {
			return [
				{ value: '1', text: 'true' },
				{ value: '0', text: 'false' }
			]
		},
		operatorItems () {
			switch (typeLookup[this.queryBuilderItem.dataType]) {
				case 'number':
					return numberItems
				case 'string':
					return stringItems
				case 'date':
					return dateItems
				case 'bit':
					return bitItems
				default:
					return []
			}
		}
	},
	created () {
		if (this.queryBuilderItem.filter) {
			this.query = this.queryBuilderItem.filter
		}
		this.showOrHideAddFieldButton()
		this.countCharactersUsed()
	},
	methods: {
		confirmFilter () {
			this.query.operatorText = this.operatorItems.find(item => item.value === this.query.operator).text
			this.$emit('confirm', this.query)
		},
		onOperatorChanged (operator) {
			const fieldsNeeded = operator === 5 ? 2 : 1
			if (this.query.values.length > fieldsNeeded) {
				this.query.values.splice(fieldsNeeded)
			}
			while (this.query.values.length < fieldsNeeded) {
				this.addField()
			}
			this.countCharactersUsed()
			this.showOrHideAddFieldButton()
		},
		showOrHideAddFieldButton () {
			if (this.query.operator === 1 || this.query.operator === 2) {
				this.showAddFieldButton = true
			} else {
				this.showAddFieldButton = false
			}
		},
		addField () {
			this.query.values.push({ value: null })
			this.showOrHideAddFieldButton()
			this.countCharactersUsed()
		},
		removeField (index) {
			this.query.values.splice(index, 1)
			this.countCharactersUsed()
		},
		countCharactersUsed () {
			switch (this.query.operator) {
				case 1:
					this.characterCount = this.query.values.length === 1 ? 1 : 4
					this.characterCount += this.query.values.length - 1
					break
				case 2:
					this.characterCount = this.query.values.length === 1 ? 2 : 8
					this.characterCount += this.query.values.length - 1
					break
				case 3:
				case 4:
					this.characterCount = 1
					break
				case 5:
					this.characterCount = 13
					break
				default:
					this.characterCount = 0
					break
			}
			this.query.values.forEach(element => {
				if (element.value) this.characterCount += element.value.length
				if (this.typeToDisplay === 'date' || this.typeToDisplay === 'string') {
					this.characterCount += 2
				}
				if (this.characterCount > 500) {
					this.filterMaxLengthReached = true
				}	else {
					this.filterMaxLengthReached = false
				}
			})
		}
	}
}
</script>
