<template>
	<Modal width="500px">
		<template #modal-title>
			Enable Export Location "{{ locationName }}" ?
		</template>
		<template #modal-content>
			The selected Export Location is currently disabled.
			<br><br>
			Would you like to enable it?
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('cancel')">
				Cancel
			</SecondaryActionButton>
			<SecondaryActionButton @click="$emit('decline')">
				No
			</SecondaryActionButton>
			<PrimaryActionButton @click="$emit('confirm')">
				Yes
			</PrimaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
export default {
	components: { PrimaryActionButton, SecondaryActionButton, Modal },
	props: {
		locationName: String
	}
}
</script>
